import React, { Fragment, useState } from 'react'
import BodyWrapper from '../../layouts/body/body'
import StarIcon from '../../assets/images/star.png'
import { Caption, Heading3, Para } from '../../components/typography/typography'
import { PrimaryButton } from '../../components/buttons/buttons'
import { FontWeight } from '../../components/typography/typography'
import Footer from '../../layouts/footer/footer'
import { Microphone } from 'phosphor-react'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { convertAudio, uploadAudioFile } from '../../services/backend'
import { useSelector } from 'react-redux'
// import { conversationSelector } from '../../features/conversation'
import { practiseSelector } from '../../features/practise'
import './style.css'
// import useSpeechToAudio from '../../components/audio-player/audioPlayer'
import { base64toFile } from '../../utils/utils'

export const ChatScreen: React.FC = () => {
  const storedConversation = useSelector(practiseSelector.getPractiseConversation) || []
  const lastConversation = (storedConversation[storedConversation.length - 1] && storedConversation[storedConversation.length - 1].content) || 'Tell me about Yourself'
  const questionList = (lastConversation.replaceAll('- ', '').split("\n")).filter((el: String) => {return el.length > 75})

  // const question = useSelector(conversationSelector.getCurrentQuestion) || ''
  const practiseSessionsId =
    useSelector(practiseSelector.getPractiseSessionId) || ''
  const question = questionList[1].slice(2)
  const startText = question || 'Tell me about Yourself'

  const [isMicActive, setMicValue] = useState(false)
  const [textValue, setTextValue] = useState<any>([])
  const [isLoading, setLoader] = useState(false)

  const recorderControls = useAudioRecorder()
  const addAudioElement = (blob: Blob) => {
    const url = URL.createObjectURL(blob)
    const audio = document.createElement('audio')
    // In the below variable you will get audio value which we can submit in api
    audio.src = url
    audio.controls = true
    document.body.appendChild(audio)

    //TODO get this values from the practise Session State Which which was store at the onboarding selectAssesmentParams Screens
    // const practiseSessionsId = "64ba148fa92812130dcc9b6f"
    setLoader(true)

    uploadAudioFile(practiseSessionsId, question, blob)
      .then(async (nextConversationRes) => {
        const conversation = nextConversationRes.data.data.conversation
        const newlyGeneratedPlainText =
          (conversation[conversation.length - 1] &&
            conversation[conversation.length - 1].content) ||
          'Failed to Generate Text'
          console.log(newlyGeneratedPlainText);
          
          let newlyGeneratedText = [{text: newlyGeneratedPlainText, heading: 'Next Question'}]
          if(conversation.length > 10){
            const analysisReport = (conversation[conversation.length - 3] && conversation[conversation.length - 3].content) ||
            'Failed to Generate Text'
            newlyGeneratedText = [{text: analysisReport, heading: 'Analysis Report'}, {text: newlyGeneratedPlainText, heading: 'Communication Analysis Report'}]
            
          }

          const response = await convertAudio(newlyGeneratedPlainText);
          const file = base64toFile(response.data.data, 'audio-file');
          const audioUrl = URL.createObjectURL(file);
          const audioElement = new Audio(audioUrl);
          audioElement.play();
          
          // const { playSound } = useSpeechToAudio(newlyGeneratedPlainText);
          // setTimeout(()=>{
          //   playSound();
          // }, 2500)
        // const newlyGeneratedText = newlyGeneratedPlainText
        //   .split('\n\n')
        //   .map((el: string) => {
        //     const innerEl = el.replaceAll('- ', '')?.split('\n')
        //     return {
        //       heading: innerEl[0],
        //       score: innerEl[1].split(': ')[1],
        //       text: innerEl[2].split(': ')[1] || innerEl.slice(3, 6).join(),
        //     }
        //   })
        setTextValue([...textValue, ...newlyGeneratedText])
        console.log(newlyGeneratedText)
        console.log(textValue)

        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
      })
  }

  return (
    <Fragment>
      <BodyWrapper isBackEnabled isFooter>
        <div>
          <div className="text-center">
            <img src={StarIcon} className="mb-5 w-25 mx-auto" />
          </div>
          <div>
            <Caption textColor="var(--grayLight)">Voizo</Caption>

            <Para
              fontWeight={FontWeight.REGULAR}
              textColor="var(--white)"
              className="mb-3"
            >
              {startText}
            </Para>

            {textValue.map((data: any) => {
              return (
                <div className="pe-4 mb-3 ">
                  <div className='d-flex align-items-center rating-wrapper'> <Heading3
                    fontWeight={FontWeight.SEMIBOLD}
                    textColor="var(--white)"
                  >
                    {data?.heading}
                  </Heading3>

                  <Heading3
                    fontWeight={FontWeight.SEMIBOLD}
                    textColor="var(--primary)"
                    className="my-2 ms-3"
                  >
                    {data?.score}
                  </Heading3></div>
                 

                  <Heading3
                    fontWeight={FontWeight.REGULAR}
                    textColor="var(--white)"
                    className='response-box'
                  >
                    {data?.text}
                  </Heading3>
                </div>
              )
            })}

            {isLoading && (
              <Para
                fontWeight={FontWeight.SEMIBOLD}
                textColor="var(--grayLight)"
                className="my-4"
              >
                Loading....
              </Para>
            )}
          </div>
          <Footer>
            {!isMicActive ? (
              <div className="d-grid px-4">
                <PrimaryButton
                  isRounded
                  handleBtnClick={() => {
                    setMicValue(true)
                    recorderControls.startRecording()
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    Speak to Answer
                    <Microphone
                      size={24}
                      color="var(--black)"
                      weight="fill"
                      className="ms-2"
                    />
                  </div>
                </PrimaryButton>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <AudioRecorder
                  onRecordingComplete={addAudioElement}
                  recorderControls={recorderControls}
                  audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                  }}
                  onNotAllowedOrFound={(err: any) => console.table(err)}
                  downloadOnSavePress={false}
                  downloadFileExtension="webm"
                  showVisualizer={true}
                />
              </div>
            )}
          </Footer>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
