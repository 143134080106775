import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Login } from './pages/auth/Login'
import './index.css'
import { Name } from './pages/onboarding/name'
import { Welcome } from './pages/onboarding/welcome'
import { ResumeUpload } from './pages/onboarding/resumeUpload'
import { JDUpload } from './pages/onboarding/jdUpload'
import { SelectRole } from './pages/onboarding/selectRole'
import { SelectInterviewer } from './pages/onboarding/selectInterviewer'
import { SelectAssessmentParams } from './pages/onboarding/selectAssessmentParams'
import { ChatScreen } from './pages/conversation/chatScreen'
import { Dashboard } from './pages/dashboard/dashboard'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { TopHighlights } from './pages/onboarding/topHighlights'
import { QuestionList } from './pages/conversation/questionList'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      {/* <Navbar /> */}
      <div>
        <Routes>
          {/* <Route path="/" element={<LandingPage />} /> */}
          <Route path="/" element={<Login />} />
          <Route path="/name" element={<Name />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/upload-resume" element={<ResumeUpload />} />
          <Route path="/upload-jd" element={<JDUpload />} />
          <Route path="/select-role" element={<SelectRole />} />
          <Route path="/select-interviewer" element={<SelectInterviewer />} />
          <Route
            path="/select-assessment-params"
            element={<SelectAssessmentParams />}
          />
          <Route path="/talk-with-voizo" element={<ChatScreen />} />
          <Route path="/question-list" element={<QuestionList />} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/top-highlights" element={<TopHighlights />} />
          
        </Routes>
        <ToastContainer />
      </div>
    </BrowserRouter>
  )
}

export default App
