import React, { Fragment } from 'react'
import { PrimaryButton } from '../../components/buttons/buttons'
import { PrimaryTextfield } from '../../components/textfield/textfield'
import { Heading2, Para } from '../../components/typography/typography'
import BodyWrapper from '../../layouts/body/body'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { actionTypes, userSelectors } from '../../features/user'
import './style.css'
import { updateUserName } from '../../services/backend'
import useSpeechToAudio from '../../components/audio-player/audioPlayer'

export const Name: React.FC = () => {
  // const [name, setName] = useState('')
  let name = useSelector(userSelectors.getUserName)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const speechText = `Welcome ${name.split(" ")[0]}, I am Voizo, your communication Co-pilot.`
  const { playSound } = useSpeechToAudio(speechText);

  const onNameUpdate = () => {
    console.log(name);
    setTimeout(()=>{
      playSound();
    },500)
    updateUserName(name);
    navigate('/welcome')
  }

  return (
    <Fragment>
      <BodyWrapper>
        <div className="text-center d-flex flex-column justify-content-md-center justify-content-between h-100">
          <div className="mt-7 mt-md-0">
            <Para textColor="var(--white)" className="mb-2">
              Successfully Loggedin!
            </Para>
            <Heading2 textColor="var(--white)" className="mb-5">
              Just tell us your name
            </Heading2>

            <PrimaryTextfield
              value={name}
              fieldName={'user-name'}
              onChange={(fieldName: string, value: string | number) => {dispatch({ type: actionTypes.SET_USER_NAME, name: value })}  }
              type={''}
              placeholder="Your full name"
            />
          </div>
          <div className="d-grid mt-5">
            <PrimaryButton handleBtnClick={onNameUpdate}>
              Done
            </PrimaryButton>
          </div>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
