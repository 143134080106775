import { toast } from 'react-toastify'
import './style.css'

export const notifyError = (msg: string) =>
  toast.error(msg, {
    position: 'bottom-center',
    hideProgressBar: true,
    style: { borderRadius: '8px', margin: '8px' },
  })

  export function base64toFile(base64Data: any, fileName: any) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const file = new File(byteArrays, fileName, { type: 'application/octet-stream' });
    return file;
  }
  
