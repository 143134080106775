import { ArrowLeft } from 'phosphor-react'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import './style.css'

interface BodyProps {
  children: ReactNode
  withGradient?: boolean
  isBackEnabled?: boolean
  isFooter?: boolean
  screenName?: string
}

export default function BodyWrapper({
  children,
  withGradient = true,
  isBackEnabled = false,
  isFooter = false,
  screenName,
}: BodyProps) {
  const navigate = useNavigate()

  return (
    <div className={`${withGradient ? `primary-gradient` : ''}`}>
      {(isBackEnabled || screenName) && (
        <div className="row p-3 m-0">
          <div className="col-2">
            <ArrowLeft
              size={24}
              color="var(--white)"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="col-8"></div>
          <div className="col-2"></div>
        </div>
      )}
      <div style={{minHeight: (isBackEnabled || screenName) ? 'calc(100vh - 55px)': '100vh', paddingBottom: isFooter? '100px': '24px'}} className={`body-wrapper px-3 pt-3 `}>
        <div className="w-md-50">{children}</div>
      </div>
    </div>
  )
}
