import React, { Fragment } from 'react'
import BodyWrapper from '../../layouts/body/body'
import './style.css'
import { Caption, Para } from '../../components/typography/typography'
import AddNotesIcon from '../../assets/images/add_notes.svg'
import { Card } from '../../components/card/card'
import { notifyError } from '../../utils/utils'


export const Dashboard: React.FC = () => {

  return (
    <Fragment>
      <BodyWrapper isBackEnabled>
        <div>
          <Para textColor="var(--white)" className="mb-3">
            Start your interview practice
          </Para>

          <div className="doc-upload-cta mb-5 text-center" onClick={() => {}}>
            <img src={AddNotesIcon} />
            <Para textColor="var(--white">Upload JD</Para>
          </div>

          <Caption textColor="var(--white)">Coming Soon</Caption>

          <div className="row mt-3">
            <div className="col-6">
              <Card label="Presentaions" handleBtnClick={() => {notifyError('Something went wrong')}} />
            </div>
            <div className="col-6">
              <Card label="Meeting Analysis" handleBtnClick={() => {}} />
            </div>
          </div>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
