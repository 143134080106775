import axios from "axios";


let baseURL = "https://beta-api.voizo.ai:6101";

console.log("ENV Variable " + JSON.stringify(process.env));
if(process.env.REACT_APP_ENV === "production"){
  baseURL = "https://beta-prod-api.voizo.ai:6101"
}

const baseHeaders = {
  'Content-type': 'application/json',
};


const userHeader = () => {
  //TODO get it from state
  const email = localStorage.getItem("email") || ''
  return {
    'x-access-token': email
  }
}

export const createUser = async (email: string, name: string | null, phone: string | null , photoURL: string | null ) => {
  return axios.post(`${baseURL}/public/api/user`, {
    email: email,
    name: name,
    phone: phone,
    photoURL: photoURL
  }, {
    headers:  {
      ...baseHeaders,
      ...userHeader()
    }
  });
}


export const updateUserName = async (name: string) => {
  return axios.put(`${baseURL}/api/user`, {
    name: name,
  }, {
    headers:  {
      ...baseHeaders,
      ...userHeader()
    }
  });
}


export const uploadPractiseFile = async (fileType: string, file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`${baseURL}/api/practise/upload?fileType=${fileType}`, formData , {
    headers:  {
      ...baseHeaders,
      ...userHeader(),
      "Content-Type": "multipart/form-data"
    }
  });
}


export const uploadAudioFile = async (practiseSessionId: string, question: string | null, file: Blob) => {
  const formData = new FormData()
  formData.append('file', file, 'audio.webm')
  if(question) formData.append('question', question)
  return axios.post(`${baseURL}/api/practise/uploadAudio?practiseSessionId=${practiseSessionId}`, formData , {
    headers:  {
      ...baseHeaders,
      ...userHeader(),
      "Content-Type": "multipart/form-data"
    }
  });
}


export const startPractiseConversation = async (userFileContextId: string, practiseSessionFileContextId: string, userPreference: any) => {
  return axios.post(`${baseURL}/api/practise/conversation`, {
    userFileContextId: userFileContextId,
    practiseSessionFileContextId: practiseSessionFileContextId,
    userPreference: userPreference,
  }, {
    headers:  {
      ...baseHeaders,
      ...userHeader()
    }
  });
}

export const convertAudio = async ( speechText: string ) => {
  return axios.post(`${baseURL}/api/practise/speech-data`, {
    text: speechText,
    modelName: "A",
    audioConfig: {
        audioEncoding: "MP3",
        speakingRate: 0.9,
        pitch: 1,
        volumeGainDb: 0
    }
}, {
    headers:  {
      ...baseHeaders,
      ...userHeader()
    }
  });
}