import React, { Fragment, useState } from 'react'
import BodyWrapper from '../../layouts/body/body'
import './style.css'
import StarIcon from '../../assets/images/star.png'
import { FontWeight, Para } from '../../components/typography/typography'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes } from '../../features/practise'
import { practiseSelector } from '../../features/practise'
import { PrimaryButton } from '../../components/buttons/buttons'
import Footer from '../../layouts/footer/footer'
import { convertAudio, startPractiseConversation } from '../../services/backend'
import useSpeechToAudio from '../../components/audio-player/audioPlayer'
import { userSelectors } from '../../features/user'
import { base64toFile } from '../../utils/utils'



export const SelectRole: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const preferences = useSelector(practiseSelector.getPractisePreferences) || {}
  const jdMetaData = useSelector(practiseSelector.getPractiseJdMetadata) || {}
  const resumeId = useSelector(practiseSelector.getPractiseResumeId) || ''
  const jdId = useSelector(practiseSelector.getPractiseJdid) || ''
  const name = useSelector(userSelectors.getUserName) || 'NA'


  const [isLoading, setLoader] = useState(false)
  const speechText = `${name.split(" ")[0]} i am generating a question for your interview preprations`
  const { playSound } = useSpeechToAudio(speechText);
  

  let JobRole: string = '';
  let topHightLights = [
    ["Key Technical Attributes:", "Product Management", "User Experience", "Software Engineering"],
    ["Key Behavioral Attributes:", "Problem-solving", "Fast-paced environment", "High attention to detail"],
    ["Key Communication Attributes:", "Strong communication", "Influencing skills", "Listening skills"],
  ]
  try {
    JobRole = (jdMetaData.displayInfo || '').replaceAll('"','')
    topHightLights = jdMetaData.summaryInfo.split("\n\n")
      .map((el: String) => {
          return el.split("\n").map( (innerEl : string, index: number ) => {  
            if(index != 0) return innerEl.slice(2) 

            return innerEl;
          }) 
      }) || [[]];
    console.log(topHightLights);
  } catch (error) {
    console.log(error)
    navigate('/')
  }

  const startPractiseSession = () => {
    setLoader(true)
    preferences.role = JobRole;
    setTimeout(()=>{
      playSound();
    },100)
    dispatch({
      type: actionTypes.SET_PRACTISE_PREFERENCES,
      preferences: preferences,
    })
    startPractiseConversation(resumeId, jdId, {
      ...preferences,
    })
      .then(async (practiseSession) => {
        dispatch({
          type: actionTypes.SET_PRACTISE_CONVERSATION_DATA,
          conversation: practiseSession.data.data.conversation,
        })
        dispatch({
          type: actionTypes.SET_PRACTISE_SESSION_ID,
          _id: practiseSession.data.data._id,
        })
        // navigate('/question-list')
        const storedConversation = practiseSession.data.data.conversation;
        const lastConversation = (storedConversation[storedConversation.length - 1] && storedConversation[storedConversation.length - 1].content) || 'Tell me about Yourself'
        const questionList = (lastConversation.replaceAll('- ', '').split("\n")).filter((el: String) => {return el.length > 75})
        const response = await convertAudio(questionList[1].slice(2));
        const file = base64toFile(response.data.data, 'audio-file');
        const audioUrl = URL.createObjectURL(file);
        const audioElement = new Audio(audioUrl);
        setTimeout( () => {
          audioElement.play();
        }, 1000)
        navigate('/talk-with-voizo')
        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
      })
  }

  return (
    <Fragment>
      <BodyWrapper isBackEnabled>
        <div className="text-center">
          <img src={StarIcon} className="mb-5 w-25 mx-auto" />
          <div>
            <Para textColor="var(--white" >
              We think you are applying for the role of
            </Para>
            <Para textColor="var(--white" fontWeight={FontWeight.SEMIBOLD} className="mb-5">
              {JobRole}
            </Para>
            {/* <Caption textColor="var(--white">
              Let us know your the role you are applying for
            </Caption>
            <PrimaryTextfield
              value={JobRole}
              fieldName={'role'}
              onChange={() => { handleSelection() } }
              type={''}
              placeholder="Your Role"
            /> */}
          </div>
          <div className="mt-3 ">
            <ol>
              {/* <li>
                <Para textColor="var(--white)" className="text-start">
                  {topHightLights[0]}
                </Para>
              </li> */}
              { topHightLights.slice(0).map((data, index) => (
                  <div key={index + 1}>
                    <Para textColor="var(--white)" className="text-start mt-2" fontWeight={FontWeight.SEMIBOLD}>
                      {data[0]}
                    </Para>
                    <ol>
                      { data.slice(1).map((internalData: string, internalIndex: number) => (
                        <li key={internalIndex + 1}>
                          <Para textColor="var(--white)" className="text-start">
                            {internalData}
                          </Para>
                        </li>
                      )) }
                    </ol>
                  </div>
                ))}
            </ol>
          </div>
          <Footer>
          <div className="d-grid mt-5">
            <PrimaryButton handleBtnClick={startPractiseSession} isLoading={isLoading}>
              Start Conversation
            </PrimaryButton>
          </div>

          </Footer>
         
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
