import { combineReducers, createStore } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'
import { CounterReducer } from './features/counter'
import { UserReducer } from './features/user'
import { practiseReducer } from './features/practise'
import { conversationReducer } from './features/conversation'

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  count: CounterReducer,
  user: UserReducer,
  practise: practiseReducer,
  conversation: conversationReducer
})

const store = createStore(
  rootReducer,
  /* preloadedState, */ devToolsEnhancer({})
)

export default store
