import React from 'react'
import { Para } from '../typography/typography'
import './style.css'

export interface CardProps {
  handleBtnClick: () => any
  img?: string
  bgImg?: string
  label?: string
}

export function Card(props: CardProps) {
  function onClick() {
    props.handleBtnClick()
  }
  return (
    <div
      onClick={onClick}
      style={{ backgroundImage: `url(${props.bgImg})` }}
      role="button"
      className="main-card"
    >
      {props.img && <img src={props.img} />}
      <Para textColor="var(--white)">{props?.label ?? ''}</Para>
    </div>
  )
}
