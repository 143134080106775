/* eslint-disable @typescript-eslint/default-param-last */

import { SET_CONVERSATION_QUESTION } from './actionTypes'
import { ConversationActionTypes } from './types'

const initialState = {
  currentQuestion: ''
}

export default (state = initialState, action: ConversationActionTypes) => {
  switch (action.type) {
    case SET_CONVERSATION_QUESTION:
      return { ...state, currentQuestion: action.currentQuestion }
    default:
      return state
  }
}
