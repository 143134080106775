import React, { ReactNode } from 'react';
import './style.css';

interface FooterLayoutProps {
  children: ReactNode;
}

export default function Footer(props: FooterLayoutProps) {
  return (
    <div
      className={`footer`}
    >
      {props.children}
    </div>
  );
}
