import React, { Fragment, useState } from 'react'
import BodyWrapper from '../../layouts/body/body'
import './style.css'
import StarIcon from '../../assets/images/star.png'
import { FontWeight, Para } from '../../components/typography/typography'
import AddNotesIcon from '../../assets/images/add_notes.svg'
import { useNavigate } from 'react-router-dom'
import { useFilePicker, FileContent } from 'use-file-picker'
import { uploadPractiseFile } from '../../services/backend'
import { PrimaryButton } from '../../components/buttons/buttons'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes } from '../../features/practise'
import { userSelectors } from '../../features/user'
import useSpeechToAudio from '../../components/audio-player/audioPlayer'


export const ResumeUpload: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setLoader] = useState(false);
  const name = useSelector(userSelectors.getUserName) || 'NA'
  const speechText = `Thanks ${name.split(" ")[0]}. We will be sharing a brief, impactful tour of your career highlights.`
  const { playSound } = useSpeechToAudio(speechText);

  const speechText2 = `Hey ${name.split(" ")[0]}, here are the major highlights of your resume. If this looks good, let's proceed.`
  const secondSpeech = useSpeechToAudio(speechText2);


  const [openCVFileSelector, cvDocInfo] = useFilePicker({
    multiple: true,
    readAs: 'ArrayBuffer',
    accept: ['.pdf'],
    limitFilesConfig: { max: 1 },
    // minFileSize: 1, // in megabytes
    maxFileSize: 10,
    readFilesContent: true,
  })

  const ifFilePicked = (filesContent: FileContent[]) => {
    if (filesContent.length == 0) {
      return false
    } else {
      return true
    }
  }

  let documentUploading = false;
  const isButtonDisabled = !documentUploading && !ifFilePicked(cvDocInfo.filesContent)

  const navigateToNextPage = () => {
    setTimeout(() => {
      console.log('Navigating to Select Role Page!')
      navigate('/top-highlights')
    }, 500)
    setTimeout(()=>{
      secondSpeech.playSound();
    },1000)
  }

  const getFileName = (docInfo: any) => {
    let filesContent = docInfo.filesContent
    if (filesContent.length == 0) {
      return ''
    } else {
      return filesContent[0].name
    }
  }

  const onDocumentUpload = () => {
    setTimeout(()=>{
      playSound();
    },500)
    documentUploading = true;
    documentUploading = true;
    setLoader(true);
    uploadPractiseFile("RESUME", cvDocInfo.plainFiles[0])
    .then((fileInfo)=>{
      if(fileInfo) {
        dispatch({
          type: actionTypes.SET_PRACTISE_USER_RESUME_ID,
          resumeId: fileInfo.data.data._id,
        })
        dispatch({
          type: actionTypes.SET_PRACTISE_USER_RESUME_METADATA,
          resumeMetadata: fileInfo.data.data,
        })
        navigateToNextPage()
        documentUploading = false
        setLoader(false);
      }
    })
    .catch( err => {
      console.log(err)
      setLoader(false);
    })
  }

  return (
    <Fragment>
      <BodyWrapper isBackEnabled>
        <div className="d-flex justify-content-between flex-column text-center h-100">
          <div>
          <img src={StarIcon} className="mb-5 w-25 mx-auto" />
          <div>
            <Para textColor="var(--white)" className="mb-2" fontWeight={FontWeight.SEMIBOLD}>
              {`${name}`}
            </Para>
            <Para textColor="var(--white)" className="mb-5">
              {`Upload your resume to generate a personalised interview experience.`}
            </Para>
            <div
              className="doc-upload-cta mb-3"
              onClick={() => openCVFileSelector()}
            >
              {!ifFilePicked(cvDocInfo.filesContent) ? (
                <div>
                  <img src={AddNotesIcon} />
                  <Para textColor="var(--white)">Upload your CV</Para>
                </div>
              ) : (
                <Para textColor="var(--white)">
                  {getFileName(cvDocInfo)}
                </Para>
              )}
            </div>
          </div>

          </div>
         
          <div className="d-grid mt-5">
            <PrimaryButton isDisabled={isButtonDisabled} handleBtnClick={onDocumentUpload} isLoading={isLoading}>
              Upload Resume
            </PrimaryButton>
          </div>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
