import React, { Fragment } from 'react'
import BodyWrapper from '../../layouts/body/body'
import './style.css'
import StarIcon from '../../assets/images/star.png'
import { Heading2, Para } from '../../components/typography/typography'
import { PrimaryButton } from '../../components/buttons/buttons'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelectors } from '../../features/user'
import useSpeechToAudio from '../../components/audio-player/audioPlayer'


export const Welcome: React.FC = () => {
  const navigate = useNavigate()
  const name = useSelector(userSelectors.getUserName) || 'NA'
  const isUserInActive = useSelector(userSelectors.isUserInActive)

  const speechText = ` ${name.split(" ")[0]}, upload your resume to generate a personalised interview experience.`
  const { playSound } = useSpeechToAudio(speechText);

  const onNextButtonClick = () => {
    setTimeout(()=>{
      playSound();
    },500)
    navigate('/upload-resume')
  }

  return (
    <Fragment>
      <BodyWrapper isBackEnabled>
        <div className="text-center d-flex flex-column justify-content-md-center justify-content-between h-100 ">
          <img src={StarIcon} className="mb-5 w-25 mx-auto" />
          <div>
            <Para textColor="var(--white">Welcome</Para>
            <Heading2 textColor="var(--white">{name}</Heading2>
            <div className="coming-soon-div">
              {isUserInActive ? (
                <Para textColor="var(--white">
                  Thanks for your interest in Voizo's Alpha release. Slots are
                  full, but your enthusiasm is valued! As we expand, expect an
                  invitation by July 27. Stay tuned!.
                </Para>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="d-grid mt-5">
            {isUserInActive ? (
              ''
            ) : (
              <PrimaryButton handleBtnClick={onNextButtonClick}>
                Done
              </PrimaryButton>
            )}
          </div>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
