import React from 'react'
import './style.css'

interface TextFieldProps {
  label?: string
  error?: string
  value: string | number
  disabled?: boolean
  placeholder?: string
  fieldName: string
  onChange: (fieldName: string, value: string | number) => void
  type: string
  class?: string
  info?: string
}

export function PrimaryTextfield(props: TextFieldProps) {
  function handleFocus() {
    const element = document.getElementById(props.fieldName)
    if (element) {
      element.scrollIntoView()
    }
  }
  return (
    <div className="form-group">
      <div className="text-left mb-1">
        {props?.label && (
          <label
            htmlFor={props.fieldName}
            className="normal-text font-color-gray"
          >
            {props?.label}
          </label>
        )}
      </div>
      <div className="position-relative">
        <input
          type={props.type}
          id={props.fieldName}
          className={`form-control primary-input ${props.class}`}
          placeholder={props.placeholder ? props.placeholder : ''}
          onChange={(e) => props.onChange(props.fieldName, e.target.value)}
          value={props.value}
          disabled={props.disabled ? props.disabled : false}
          onFocus={handleFocus}
        />
        {props.error && props.error.trim() !== '' && (
          <div className={'text-danger mt-1 text-left small'}>
            {props.error}
          </div>
        )}
      </div>
      {props.info && (
        <p className="text-left mt-1 small font-color-gray">{props.info}</p>
      )}
    </div>
  )
}
