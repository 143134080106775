/* eslint-disable @typescript-eslint/default-param-last */

import { SET_USER_ACTIVE_STATUS, SET_USER_EMAIL, SET_USER_EXISTING, SET_USER_NAME } from './actionTypes'
import { UserActionTypes } from './types'

const initialState = {
  name: '',
  email: '',
  inActive: true,
  existingUser: false,
}

export default (state = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case SET_USER_NAME:
      return { ...state, name: action.name }
    case SET_USER_EMAIL:
      return { ...state, email: action.email }
    case SET_USER_ACTIVE_STATUS:
      return { ...state, inActive: action.inActive }
    case SET_USER_EXISTING:
      return { ...state, existingUser: action.existingUser }
    default:
      return state
  }
}
