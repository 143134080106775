import React, { Fragment, useEffect, useState } from 'react'
import { PrimaryButton } from '../../components/buttons/buttons'
import { Caption, Heading2, Para } from '../../components/typography/typography'
import BodyWrapper from '../../layouts/body/body'
import './style.css'
import StarIcon from '../../assets/images/star.png'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { practiseSelector } from '../../features/practise'
import { userSelectors } from '../../features/user'
import useSpeechToAudio from '../../components/audio-player/audioPlayer'


export const TopHighlights: React.FC = () => {
  const navigate = useNavigate()
  const speechText = `Now let's upload a Job Description file`
  const { playSound } = useSpeechToAudio(speechText);

  const [topHightLights, setTopHighlights] = useState<string[]>([])

  const [showFullData, setShowFullData] = useState(false)

  const userName = useSelector(userSelectors.getUserName) || ''
  const resumeMetadata = useSelector(practiseSelector.getPractiseResumeMetdata) || {}
  const conversation = resumeMetadata.displayInfo 
  const resumeData = conversation.replaceAll('- ', '')
                        .replaceAll('1.', '')
                        .replaceAll('2.', '')
                        .replaceAll('3.', '')
                        .replaceAll('4.', '')
                        .replaceAll('5.', '')
                        .split('\n')
                        .filter((el: string) => {return el != ""})

  useEffect(() => {
    setTopHighlights(resumeData)
  }, [])

  const onNextButtonClick = () => {
    setTimeout(()=>{
      playSound();
    },500)
    navigate('/upload-jd')
  }

  return (
    <Fragment>
      <BodyWrapper isBackEnabled>
        <div className="text-center">
          <img src={StarIcon} className="mb-5 w-25 mx-auto" />
          <div className="mt-3 ">
            <Heading2 textColor="var(--white)" className="mb-2">
              {`Hey ${userName}`}
            </Heading2>
            <Para textColor="var(--white)" className="mb-5">
              Here are the top 5 highlights of your resume:
            </Para>
            <ol>
              <li>
                <Para textColor="var(--white)" className="text-start">
                  {topHightLights[0]}
                </Para>
              </li>
              {showFullData &&
                topHightLights.slice(1).map((data, index) => (
                  <li key={index + 1}>
                    <Para textColor="var(--white)" className="text-start">
                      {data}
                    </Para>
                  </li>
                ))}
            </ol>

            {!showFullData && (
              <div
                className="text-right"
                onClick={() => {
                  setShowFullData(true)
                }}
                role="button"
              >
                <Caption textColor="var(--primary)" className="text-end mt-3">
                  Read More
                </Caption>
              </div>
            )}

            {showFullData && (
              <div
                className="text-right"
                onClick={() => {
                  setShowFullData(false)
                }}
                role="button"
              >
                <Caption textColor="var(--primary)" className="text-end mt-3">
                  Read Less
                </Caption>
              </div>
            )}
          </div>
          <div className="d-grid mt-5">
            <PrimaryButton handleBtnClick={onNextButtonClick}>Confirm</PrimaryButton>
          </div>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
