// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut} from "firebase/auth"

let firebaseConfig = {
  apiKey: "AIzaSyB8cXR7gpBP6rjb8zvxSmXAPDCmPHwcqSQ",
  authDomain: "voizo-390908.firebaseapp.com",
  projectId: "voizo-390908",
  storageBucket: "voizo-390908.appspot.com",
  messagingSenderId: "83350781476",
  appId: "1:83350781476:web:317059b1142500dbe4a2b1",
  measurementId: "G-6T9HK0Y9DY"
}


if(process.env.REACT_APP_ENV === "production"){
  firebaseConfig = {
    apiKey: "AIzaSyD3Q_nPTMymjJdmbDS-VdD6nKQmsmquBNU",
    authDomain: "voizo-prod.firebaseapp.com",
    projectId: "voizo-prod",
    storageBucket: "voizo-prod.appspot.com",
    messagingSenderId: "359053328922",
    appId: "1:359053328922:web:b6106ac303a4a54dff87a3",
    measurementId: "G-K18JR7DFL8"
  }
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)


const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log(user);
    return user;
  } catch (err) {
    console.error(err);
  }
};

const logout = () => {
    signOut(auth);
};


export {
    auth,
    signInWithGoogle,
    logout,
    analytics
  };

