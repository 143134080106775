import React, { Fragment } from 'react'
import BodyWrapper from '../../layouts/body/body'
import { useNavigate } from 'react-router-dom'
import './style.css'
import GoogleIcon from '../../assets/images/google.svg'
// import TrueCallerIcon from '../../assets/images/truecaller.svg'

import LoginImage from '../../assets/images/login-image.png'
import { Heading2, Para } from '../../components/typography/typography'
import { SocialLoginBtn } from '../../components/buttons/buttons'
import { signInWithGoogle } from '../../firebase'
import { useDispatch } from 'react-redux'
import { actionTypes } from '../../features/user'
import { createUser } from '../../services/backend'
import useSpeechToAudio from '../../components/audio-player/audioPlayer'

export const Login: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const speechText = `Welcome, I am Voizo, your communication Co-pilot.`
  const { playSound } = useSpeechToAudio(speechText);


  const handleGoogleLogin = async () => {
    const googleUser = await signInWithGoogle();
    
    dispatch({ type: actionTypes.SET_USER_NAME, name: googleUser && googleUser.displayName })
    dispatch({ type: actionTypes.SET_USER_EMAIL, email: googleUser && googleUser.email })
    if(googleUser?.email) {
      localStorage.setItem("email", googleUser.email)
      const userResponse = await createUser(googleUser?.email, googleUser?.displayName, googleUser?.phoneNumber, googleUser?.photoURL);
      const user = userResponse.data.data;
      console.log(user, googleUser)
      console.log(googleUser.displayName);

      dispatch({ type: actionTypes.SET_USER_ACTIVE_STATUS, inActive: user && user.inActive })
      dispatch({ type: actionTypes.SET_USER_EXISTING, existingUser: user && user.existingUser })
      if(user.existingUser) {
        navigate('/welcome')
        playSound();
      } else {
        navigate('/name')
      }
    }
  }


  return (
    <Fragment>
      <BodyWrapper>
        <div className="login-body">
          <div>
            <img src={LoginImage} className="my-5" />
            <Heading2 textColor="var(--white)" className="text-center">
              Meet Voizo!
            </Heading2>
            <Heading2 textColor="var(--white)" className="text-center mb-5">
            Your communication co-pilot
            </Heading2>
          </div>
          <div className='text-center'>
            <Para textColor="var(--white)" className="mb-2">
              Login Now
            </Para>
            <div className="mx-3 mt-4">
              <SocialLoginBtn
                handleBtnClick={() => {
                  handleGoogleLogin()
                }}
                img={GoogleIcon}
                className="mb-3"
              >
                Google Login
              </SocialLoginBtn>
              {/* <SocialLoginBtn
                handleBtnClick={() => navigate('/name')}
                img={TrueCallerIcon}
              >
                Truecaller Login
              </SocialLoginBtn> */}
            </div>
          </div>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
