import React, { Fragment, useState } from 'react'
import BodyWrapper from '../../layouts/body/body'
import './style.css'
import StarIcon from '../../assets/images/star.png'
import { Para } from '../../components/typography/typography'

import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../components/buttons/buttons'
import { startPractiseConversation } from '../../services/backend'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes } from '../../features/practise'
import { practiseSelector } from '../../features/practise'
import { CheckCircle } from 'phosphor-react'

export const SelectAssessmentParams: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const jdMetadata = useSelector(practiseSelector.getPractiseJdMetadata) || {}
  const conversation = (jdMetadata.conversation && jdMetadata.conversation[4] && jdMetadata.conversation[4].content) || ''; 
  const parameterListLocal : Array<string> = ((conversation.split(':')[1]).replaceAll('\"', '')
                      .replaceAll('1.', '')
                      .replaceAll('2.', '')
                      .replaceAll('3.', '')
                      .replaceAll('4.', '')
                      .replaceAll('5.', '')
                      .split('\n')).slice(2,7) || ['']

  const modifiedParameterList = parameterListLocal.map((value) => ({
    value,
    isSelected: true,
  }))

  const resumeId = useSelector(practiseSelector.getPractiseResumeId) || ''
  const jdId = useSelector(practiseSelector.getPractiseJdid) || ''
  const preferences = useSelector(practiseSelector.getPractisePreferences) || {}

  const [isLoading, setLoader] = useState(false)

  const [parameterList, setParameterList] = useState(modifiedParameterList)

  const addOrRemovefromParameterList = (clickedParameter: any) => {
    setParameterList((prevList) =>
      prevList.map((item) =>
        item.value === clickedParameter
          ? { ...item, isSelected: !item.isSelected }
          : item
      )
    )
  }

  const startPractiseSession = () => {
    setLoader(true)
    const selectedPreferences: string[] = []
    parameterList.forEach( pref => {
      if(pref.isSelected) selectedPreferences.push(pref.value)
    })
    preferences.softSkills = selectedPreferences

    dispatch({
      type: actionTypes.SET_PRACTISE_CONVERSATION_DATA,
      preferences: preferences,
    })
    startPractiseConversation(resumeId, jdId, {
      ...preferences,
    })
      .then((practiseSession) => {
        dispatch({
          type: actionTypes.SET_PRACTISE_CONVERSATION_DATA,
          conversation: practiseSession.data.data.conversation,
        })
        dispatch({
          type: actionTypes.SET_PRACTISE_SESSION_ID,
          _id: practiseSession.data.data._id,
        })
        navigate('/question-list')
        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
      })
  }

  return (
    <Fragment>
      <BodyWrapper isBackEnabled>
        <div className="text-center">
          <img src={StarIcon} className="mb-5 w-25 mx-auto" />
          <div>
            <Para textColor="var(--white" className="mb-5">
              What parameters you want to focus? you can select multiple options
            </Para>
            <div>
              {parameterList.map((item) => (
                <div
                  key={item.value}
                  onClick={() => addOrRemovefromParameterList(item.value)}
                  className={`multiple-option-box mb-2 text-start ${
                    item.isSelected ? 'selected' : 'not-selected'
                  } `}
                >
                  <CheckCircle
                    size={24}
                    weight="fill"
                    color={item.isSelected ? 'var(--green)' : 'var(--gray)'}
                  />{' '}
                  {item.value}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="d-grid mt-5">
          <PrimaryButton
            handleBtnClick={() => startPractiseSession()}
            isLoading={isLoading}
          >
            Next
          </PrimaryButton>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
