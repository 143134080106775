import  { useState, useEffect } from 'react';
import { base64toFile } from '../../utils/utils';
import { convertAudio } from '../../services/backend';
import  welcomeAudio  from '../../assets/audio/welcome-audio.json';

function useSpeechToAudio(speechText: any) {
  const [audioURL, setAudioUrl] = useState<any>(null);

  useEffect(() => {
    const convertTextToSpeech = async () => {
      try {
        //Hack
        let file;
        if(speechText == "Welcome, I am Voizo, your communication Co-pilot."){
          file = base64toFile(welcomeAudio.data, 'audio-file');
        } else {
          const response = await convertAudio(speechText);
          file = base64toFile(response.data.data, 'audio-file');
        }
        const audioUrl = URL.createObjectURL(file);
        setAudioUrl(audioUrl);
      } catch (error) {
        console.error('Error converting audio:', error);
      }
    };

    convertTextToSpeech();
  }, [speechText]);

  const playSound = () => {
    if (audioURL) {
      const audioElement = new Audio(audioURL);
      audioElement.play();
      return () => {
        // Clean up the audio element when the component unmounts.
        audioElement.pause();
        audioElement.src = '';
      };
    }
  };

  return { playSound };
}

export default useSpeechToAudio;
