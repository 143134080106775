import React, { Fragment } from 'react'
import BodyWrapper from '../../layouts/body/body'
import './style.css'
import StarIcon from '../../assets/images/star.png'
import { Para } from '../../components/typography/typography'
import { OptionButton } from '../../components/buttons/buttons'
import Dumble from '../../assets/images/dumble.svg'
import Other from '../../assets/images/other.svg'
import Tag from '../../assets/images/tag.svg'
import Rainbow from '../../assets/images/rainbow.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes } from '../../features/practise'
import { practiseSelector } from '../../features/practise'


export const SelectInterviewer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const preferences = useSelector(practiseSelector.getPractisePreferences) || {}


  const handleSelection = (selectedInterviewer: string) => {
    preferences.interviewer = selectedInterviewer;
    dispatch({
      type: actionTypes.SET_PRACTISE_CONVERSATION_DATA,
      preferences: preferences,
    })
    console.log("Selected Interviewer " + selectedInterviewer)
  }

  return (
    <Fragment>
      <BodyWrapper isBackEnabled>
        <div className="text-center">
          <img src={StarIcon} className="mb-5 w-25 mx-auto" />
          <div>
            <Para textColor="var(--white" className="mb-5">
              Who is the interview with?
            </Para>
            <OptionButton handleBtnClick={() => {handleSelection('HR'); navigate('/select-assessment-params');}} img={Dumble}>
              HR
            </OptionButton>
            <OptionButton handleBtnClick={() => {handleSelection('CXO'); navigate('/select-assessment-params');}} img={Rainbow}>
              CXO
            </OptionButton>
            <OptionButton handleBtnClick={() => {handleSelection('Manager'); navigate('/select-assessment-params');}} img={Tag}>
              Manager
            </OptionButton>
            <OptionButton handleBtnClick={() => {handleSelection('peers'); navigate('/select-assessment-params');}} img={Tag}>
              Peers
            </OptionButton>
            <OptionButton handleBtnClick={() => {handleSelection('Other'); navigate('/select-assessment-params');}} img={Other}>
              Other
            </OptionButton>
          </div>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
