/* eslint-disable @typescript-eslint/default-param-last */

import { SET_PRACTISE_CONVERSATION_DATA, SET_PRACTISE_PREFERENCES, SET_PRACTISE_SESSION_ID, SET_PRACTISE_USER_JD_ID, SET_PRACTISE_USER_JD_METADATA, SET_PRACTISE_USER_RESUME_ID, SET_PRACTISE_USER_RESUME_METADATA } from './actionTypes'
import { PractiseConversationActionTypes } from './types'

const initialState = {
  _id: '',
  conversation: [],
  resumeId: '',
  resumeMetadata: {},
  jdId: '',
  jdMetadata: {},
  preferences: {
    interviewer : 'string',
    role: 'string',
    softSkills: [''],
  }
}

export default (state = initialState, action: PractiseConversationActionTypes) => {
  switch (action.type) {
    case SET_PRACTISE_CONVERSATION_DATA:
      return { ...state, conversation: action.conversation }
    case SET_PRACTISE_USER_RESUME_ID:
      return { ...state, resumeId: action.resumeId }
    case SET_PRACTISE_USER_RESUME_METADATA:
      return { ...state, resumeMetadata: action.resumeMetadata }
    case SET_PRACTISE_USER_JD_ID:
      return { ...state, jdId: action.jdId }
    case SET_PRACTISE_USER_JD_METADATA:
      return { ...state, jdMetadata: action.jdMetadata }
    case SET_PRACTISE_PREFERENCES:
      return { ...state, preferences: action.preferences }
    case SET_PRACTISE_SESSION_ID:
      return { ...state, _id: action._id }
    default:
      return state
  }
}
