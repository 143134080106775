import React from 'react'
import { Para } from '../typography/typography'
import { ButtonType } from './../../models/ui.enum'
import './style.css'
import Loader from '../loader'

export interface ButtonProps {
  className?: string
  handleBtnClick: () => any
  isDisabled?: boolean
  type?: ButtonType
  id?: string
  preventDefault?: boolean
  stopPropagation?: boolean
  children: string | JSX.Element
  img?: string
  icon?: JSX.Element
  btnTextColor?: string
  btnBackgroundColor?: string
  isRounded?: boolean
  isLoading?: boolean
}

type BaseButtonProps = Omit<ButtonProps, 'children'> & {
  children: string | JSX.Element
}

function BaseButton(props: BaseButtonProps) {
  function onClick(e: any) {
    if (props.preventDefault) {
      e?.preventDefault()
    }
    if (props.stopPropagation) {
      e?.stopPropagation()
    }
    if (!props.isLoading) {
      props.handleBtnClick()
    }
  }
  return (
    <button
      className={props.className}
      onClick={onClick}
      disabled={props.isDisabled}
      type={props.type || ButtonType.BUTTON}
      id={props.id}
      style={{
        color: props.btnTextColor,
        backgroundColor: props.btnBackgroundColor,
        borderRadius: props.isRounded ? '50px' : '',
      }}
    >
      {props.isLoading ? <Loader /> : props.children}
    </button>
  )
}

export function PrimaryButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`btn btn-primary btn-full d-flex align-items-center justify-content-center ${props.className}`}
    />
  )
}

export function SocialLoginBtn(props: ButtonProps) {
  return (
    <BaseButton {...props} className={`btn btn-light w-100 ${props.className}`}>
      <div className="row align-items-center m-0 gx-0">
        <div className="col-2 text-start">{props.img && <img src={props.img} />}</div>
        <div className="col-10 text-center pe-4">{props.children}</div>
      </div>
    </BaseButton>
  )
}

export function OptionButton(props: ButtonProps) {
  return (
    <div
      className={`option-btn d-flex align-items-center ${
        props.img ? 'justify-content-start' : 'justify-content-center'
      } `}
      role="button"
      onClick={props.handleBtnClick}
    >
      {props.img && <img src={props.img} className="me-2" />}
      <Para>{props.children}</Para>
    </div>
  )
}
