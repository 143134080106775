import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './store'
import * as Sentry from "@sentry/react";

import App from './App'

Sentry.init({
  dsn: "https://cc4e32bdda3299a1e3124ae40785a6fd@o4505577697837056.ingest.sentry.io/4505623874306048",
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container!)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
