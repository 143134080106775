import React, { Fragment } from 'react'
import BodyWrapper from '../../layouts/body/body'
import StarIcon from '../../assets/images/star.png'
import { Caption, Para } from '../../components/typography/typography'
import './style.css'
import { useSelector } from 'react-redux'
import { practiseSelector } from '../../features/practise'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actionTypes } from '../../features/conversation'


export const QuestionList: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const conversation = useSelector(practiseSelector.getPractiseConversation) || []
  const startText = (conversation[conversation.length - 1] && conversation[conversation.length - 1].content) || 'Tell me about Yourself'
  const questionList = (startText.replaceAll('- ', '').split("\n")).filter((el: String) => {return el.length > 75})

  const goToChatScreen = (question: string) => {
    console.log(question)
    dispatch({
      type: actionTypes.SET_CONVERSATION_QUESTION,
      currentQuestion: question,
    })
    navigate('/talk-with-voizo')
  }

  function questionBox(question: string) {
    return (
      <div className="question-box mb-3" onClick={ () => goToChatScreen(question) }>
        <div className="p-3">
          <Para textColor="var(--white)">{question}</Para>
        </div>
        <div className="question-box-cta text-center" role="button">
          {' '}
          <Caption textColor="var(--white)">Snooze this Question</Caption>{' '}
        </div>
      </div>
    )
  }
  return (
    <Fragment>
      <BodyWrapper isBackEnabled isFooter>
        <div>
          <div className="text-center">
            <img src={StarIcon} className="mb-5 w-25 mx-auto" />
          </div>
          <div>
            <Caption textColor="var(--grayLight)" className='mb-2'>
              Select One of the questions below
            </Caption>
            {questionList.map((data: string) => questionBox(data))}
          </div>
        </div>
      </BodyWrapper>
    </Fragment>
  )
}
